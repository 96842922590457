import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { Social } from "../models";
import {
  Layout,
  Content,
  Newsletter,
  HTMLContent,
  SectionWrapper,
  SocialIcons,
  PreviewCompatibleImage,
} from "../components";
import "../styles/_page.scss";
import "../styles/_mart.scss";

interface MartConfig {
  pageTitle: string;
  title: string;
  description: string;
  details: string[];
  featuredimage: any;
  social: Social;
}
type MartProps = {
  data: MartConfig;
  content?: React.ReactNode;
  contentComponent?: any;
  className?: string;
  helmet?: any;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export const MartTemplate: React.FC<MartProps> = ({
  data,
  contentComponent,
  content,
  helmet,
}: MartProps) => {
  const PostContent = contentComponent || Content;
  return (
    <SectionWrapper
      className="half-height container-width-l mart"
      title={"Our Mart"}
    >
      {helmet || ""}

      {typeof data.featuredimage !== "string" ? (
        <div className="mart__image">
          <PreviewCompatibleImage
            imageInfo={{
              image: data.featuredimage,
              alt: `${data.pageTitle}`,
              imgStyle: { height: "400px" },
            }}
          />
        </div>
      ) : (
        <div
          className="mart__image"
          style={{
            background: `url(${data.featuredimage}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: `center 50%`,
          }}
        />
      )}
      <h2
        className="section__header__container--title"
        style={{ margin: "2rem 0", textAlign: "center" }}
      >
        {data.description}
      </h2>
      <PostContent
        content={content}
        className="page__wrapper__content text-center"
      />
      <footer className="page__footer">
        <SocialIcons data={data.social} size={36} />
        <Newsletter style={{ margin: 0 }} />
      </footer>
    </SectionWrapper>
  );
};

export const MartPage = ({ data }) => {
  const { markdownRemark: mart } = data;
  return (
    <Layout>
      <MartTemplate
        content={mart.html}
        contentComponent={HTMLContent}
        data={mart.frontmatter}
        helmet={
          <Helmet titleTemplate="REAL CVLTVRE | %s">
            <title>{`${mart.frontmatter.pageTitle}`}</title>
            <meta
              property="og:title"
              content={`REAL CVLTVRE | ${mart.frontmatter.pageTitle}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

export default ({ data, content }) => (
  <StaticQuery
    query={graphql`
      query MartData {
        markdownRemark(frontmatter: { templateKey: { eq: "mart" } }) {
          html
          frontmatter {
            pageTitle
            title
            description
            details
            social {
              links {
                type
                url
              }
            }
            featuredimage {
              childImageSharp {
                fluid(
                  maxWidth: 1200
                  srcSetBreakpoints: [250, 500, 800, 1200]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <MartPage data={data} />}
  />
);
